@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

blink {
  animation: blink 1s infinite;
}

.highscore {
  font-size: 1.5em;
  font-weight: bold;
  color: green;
}
