.BlogThumbnailParent {
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
}

.BlogThumbnail {
  width: 330px;
  height: 190px;
  border-radius: 10px;
  transition: 300ms;
}

@media screen and (max-width: 600px) {
  .BlogThumbnail {
    width: 260px;
    height: 150px;
    border-radius: 10px;
    transition: 300ms;
  }
}

.BlogThumbnailScaled {
  scale: 1.1;
}
