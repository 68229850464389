.App {
  text-align: center;
  min-height: 100vh;
  font-size: 16px;
  padding-bottom: 50px;
}

.Content {
  width: 100%;
}

.Link {
  color: #ffcbeb;
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  transition: 200ms;
  background-color: inherit;
}

.NavLink {
  color: #ffcbeb;
  /* color: rgb(185, 185, 185); */
}

.NavLink.active {
  color: white;
  text-decoration: none !important;
  cursor: default;
}

.NavLink.active:hover {
  color: white;
  text-decoration: none !important;
  cursor: default;
}

.NavLink:hover {
  color: #ff6ec7;
  text-decoration: none;
}

.customNavbarClass {
  font-size: 20px;
  padding: 10px 0px;
}

/* tr:nth-child(even) {
  background: #cacaca;
}
tr:nth-child(odd) {
  background: #fff;
} */

.pill {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 20px;
  text-align: center;
  margin: 5px;
  font-size: 15px;
}

.footer {
  margin-top: 50px;
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 10px; */
}
